import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import React from "react";
import contact from "./assets/CONTACT.jpg";
import Head from "./Head";

function Contact() {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "",
    purpose: "",
    message: "",
    time: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zx1ebxt",
        "template_0yeb63t",
        e.target,
        "DPpmV3XbxNyPUT3_P"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show popup on successful email send
          // Reset form fields after successful submission
          setFormData({
            name: "",
            email: "",
            purpose: "",
            message: "",
            time: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      );
  };

  return (
    <>
      <div>
        <img
          src={contact}
          alt="contact"
          className="contactimg"
          loading="eager"
        />
      </div>
      <Head/>

      <h2 className="contactez" id="contact">
        {" "}
        CONTACTEZ NOUS
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "25%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
             <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20)  Égypte</option>

              <option value="+30">(+30 )  Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>

              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>
            </select>
            <input
              type="text"
              className="destinatf"
              id="purpose"
              name="tel"
              value={formData.tel}
              pattern="[0-9]*"
              title="incorrect"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "65%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="purpose">DESTINATIONS:</label>
          <select
            className="destinatf"
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
          >
            <option>VOYAGES NATIONAUX</option>
            <option>VOYAGES INTERNATIONAUX</option>
            <option>HAJJ</option>
            <option>OMRA</option>
            <option>AUTRES</option>
          </select>
          <br />
          <label htmlFor="available"> QUAND SEREZ VOUS DISPONIBILE ?</label>
          <div className="time">
            <span>
              <label htmlFor="available">[10AM-12PM]</label>
              <input
                type="radio"
                id="available"
                name="time"
                value="10-12"
                onChange={handleChange}
              />
            </span>
            <span>
              <label htmlFor="available">[12PM-04PM]</label>
              <input
                type="radio"
                id="available"
                name="time"
                value="12-16"
                onChange={handleChange}
              />
            </span>
            <span>
              <label htmlFor="available">[04PM-06PM]</label>
              <input
                type="radio"
                id="available"
                name="time"
                value="16-18"
                onChange={handleChange}
              />
            </span>
          </div>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
}

export default Contact;
