import React, { useState } from "react";
import logoFront from "./assets/logo MOUMA travel_page-0001.jpg";
import logoBack from "./assets/logoback.jpg";
import { Link } from "react-router-dom";
import "./App.css";

function Head() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="head">
      <nav className={`header ${isMenuOpen ? "active" : ""}`}>
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/#Accueil" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#services" onClick={closeMenu}>
              Services
            </a>
            <div className="dropdown-content">
              <a href="/#services">Hadj et Omra</a>
              <a href="/#services">Accompagnement</a>
              <a href="/#services">Réservation de Croisieres</a>
              <a href="/#services">Réservation de Vols</a>
              <a href="/#services">Sites Touristiques</a>
              <a href="/#services">Processus de Visa</a>
              <a href="/#services">Transport Confortable</a>
              <a href="/#services">Des Hôtels de Qualité</a>
              <a href="/#services">Mice</a>
            </div>
          </div>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#OFFRES" onClick={closeMenu}>
            OFFRES
          </a>

          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
    </div>
  );
}

export default Head;
