import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./accueil";
import Contact from "./contact";
import Footer from "./FOOTER";
import {
  NordDuMaroc,
  MarocMediterranee,
  CentreAtlantiqueMaroc,
  CentreDuMaroc,
  AtlasDuMaroc,
  SudDuMaroc,
  AfriqueDuNord,
  Afrique,
  MoyenOrient,
  Europe,
  Asie,
  SouthAmerica,
  Hadj,
  Omrah,
} from "./dest";
import { BookingOmrah } from "./bookings";
import { BookingOmrah1 } from "./bookings";

// import Offres from "./offres";
import { BookingVisa1 } from "./bookings";
import { BookingHadj } from "./bookings";
import { ISTANBUL } from "./bookings";
import { Flights } from "./bookings";
import { Cruises } from "./bookings";



function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Nord_du_Maroc" element={<NordDuMaroc />} />
        <Route path="/Maroc-mediterranee" element={<MarocMediterranee />} />
        <Route
          path="/Centre-atlantique-du-Maroc"
          element={<CentreAtlantiqueMaroc />}
        />
        <Route path="/Centre_du_Maroc" element={<CentreDuMaroc />} />
        <Route path="/Atlas_du_Maroc" element={<AtlasDuMaroc />} />
        <Route path="/Sud_du_Maroc" element={<SudDuMaroc />} />
        <Route path="/Afrique-du-nord" element={<AfriqueDuNord />} />
        <Route path="/Afrique" element={<Afrique />} />
        <Route path="/Moyen-orient" element={<MoyenOrient />} />
        <Route path="/Europe" element={<Europe />} />
        <Route path="/Asie" element={<Asie />} />
        <Route path="/South-America" element={<SouthAmerica />} />
        <Route path="/Hadj" element={<Hadj />} />
        <Route path="/Omrah" element={<Omrah />} />
        {/* <Route path="/OFFRES" element={<Offres />} /> */}
        <Route path="/booking-omrah" element={<BookingOmrah />} />
        <Route path="/booking-omrah-1446" element={<BookingOmrah1 />} />
        <Route path="/BOOKING-HADJ" element={<BookingHadj />} />
        <Route path="/ISTANBUL" element={<ISTANBUL />} />
        <Route path="/FLIGHTS" element={<Flights />} />
        <Route path="/CRUISES" element={<Cruises />} />
        <Route path="/Visa1" element={<BookingVisa1 />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default AppRouter;
