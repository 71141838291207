import { Link } from "react-router-dom";
import logoFront from "./assets/logo MOUMA travel_page-0001.jpg";
import logoBack from "./assets/logoback.jpg";
import nordimg from "./assets/nordimg.jpg";
import nordint1 from "./assets/nordint1.jpg";
import nordint2 from "./assets/nordint2.jpg";
import nordint3 from "./assets/nordint3.jpg";
import meditint1 from "./assets/meditint1.jpg";
import meditint2 from "./assets/meditint2.jpg";
import meditint3 from "./assets/meditint3.jpg";
import centreat1 from "./assets/centreat1.jpg";
import centreat2 from "./assets/centreat2.jpg";
import centreat3 from "./assets/centreat3.jpg";
import centre1 from "./assets/centre1.jpg";
import centre2 from "./assets/centre2.jpg";
import centre3 from "./assets/centre3.jpg";
import atlas1 from "./assets/atlas1.jpg";
import atlas2 from "./assets/atlas2.jpg";
import atlas3 from "./assets/atlas3.jpg";
import sud1 from "./assets/sud1.jpg";
import sud2 from "./assets/sud2.jpg";
import sud3 from "./assets/sud3.jpg";
import naf1 from "./assets/naf1.jpg";
import naf2 from "./assets/naf2.jpg";
import naf3 from "./assets/naf3.jpg";
import afrc1 from "./assets/afrc1.jpg";
import afrc2 from "./assets/afrc2.jpg";
import afrc3 from "./assets/afrc3.jpg";
import mo1 from "./assets/mo1.jpg";
import mo2 from "./assets/mo2.jpg";
import mo3 from "./assets/mo3.jpg";
import eu1 from "./assets/eu1.jpg";
import eu2 from "./assets/eu2.jpg";
import eu3 from "./assets/eu3.jpg";
import asia1 from "./assets/asia1.jpg";
import asia2 from "./assets/asia2.jpg";
import asia3 from "./assets/asia3.jpg";
import sameric1 from "./assets/sameric1.jpg";
import sameric2 from "./assets/sameric2.jpg";
import sameric3 from "./assets/sameric3.jpg";
import hadjj1 from "./assets/hadjint1.jpg";
import hadjj2 from "./assets/hadjint2.jpg";
import hadjj3 from "./assets/hadjint3.jpg";
import omra1 from "./assets/omraint1.jpg";
import omra2 from "./assets/omraint2.jpg";
import omra3 from "./assets/omraint3.jpg";
import beach from "./assets/beach.jpg";
import cmed from "./assets/cmediterranne.jpg";
import art from "./assets/artisanat.webp";
import "./App.css";
import { useEffect, useState } from "react";

export const NordDuMaroc = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={nordint2} alt="" />
        <img src={nordint1} alt="" />
        <img src={nordint3} alt="" />
      </div>{" "}
      <div
        className="dests"
        style={{
          paddingTop: "10%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Nord du Maroc</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Nord du Maroc est une région riche en culture et en histoire, avec
          des villes comme Tanger, Tétouan et Chefchaouen. Les montagnes du Rif
          offrent des paysages spectaculaires et une variété d'activités de
          plein air. La région est également connue pour sa délicieuse cuisine
          et ses marchés animés.
        </p>
        <h2>Principales attractions</h2>
        <ul>
          <li>
            Tanger: Une ville portuaire dynamique avec une histoire fascinante
            et une scène artistique animée.
          </li>
          <li>
            Chefchaouen: Connu pour ses rues bleues pittoresques et son ambiance
            détendue.
          </li>
          <li>
            Tétouan: Un trésor architectural avec son médina classé au
            patrimoine mondial de l'UNESCO.
          </li>
          <li>
            Montagnes du Rif: Offrant des randonnées magnifiques et une escapade
            paisible dans la nature.
          </li>
        </ul>
        <img src={nordimg} alt="nord" style={{ width: "150px" }}></img>

        <h2>Culture et traditions</h2>
        <p>
          La région du Nord du Maroc est imprégnée de traditions berbères et
          arabes, avec une riche histoire influencée par diverses civilisations.
        </p>
        <h2>Gastronomie</h2>
        <p>
          Ne manquez pas de déguster des plats traditionnels comme le tajine, le
          couscous et les délicieuses pâtisseries locales.
        </p>
        <h2>Marchés traditionnels</h2>
        <p>
          Explorez les souks animés où vous pourrez acheter des produits
          artisanaux, des épices et des souvenirs uniques.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const MarocMediterranee = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={meditint2} alt="" />
        <img src={meditint1} alt="" />
        <img src={meditint3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Maroc Méditerranée</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          La région de la Méditerranée au Maroc offre une fusion captivante de
          cultures, d'histoire et de paysages côtiers pittoresques. Des villes
          comme Tanger, Tétouan et Al Hoceima sont des joyaux de cette région,
          offrant une richesse d'expériences uniques.
        </p>
        <h2>Plages et activités nautiques</h2>
        <p>
          La côte méditerranéenne est réputée pour ses plages de sable doré et
          ses eaux cristallines, idéales pour la baignade, la plongée et la
          voile.
        </p>
        <img src={beach} alt="nord" style={{ width: "150px" }}></img>

        <h2>Culture et patrimoine</h2>
        <p>
          Explorez les médinas historiques, les sites archéologiques et les
          musées qui témoignent de l'histoire riche de la région, marquée par
          des influences phéniciennes, romaines et arabes.
        </p>
        <h2>Cuisine méditerranéenne</h2>
        <p>
          Dégustez une cuisine délicieuse et variée, allant des fruits de mer
          frais aux plats traditionnels méditerranéens comme le couscous de
          poisson et les tajines aux fruits de mer.
        </p>
        <img src={cmed} alt="nord" style={{ width: "300px" }}></img>

        <h2>Nature et randonnée</h2>
        <p>
          Explorez les montagnes côtières, les forêts luxuriantes et les parcs
          naturels pour des randonnées spectaculaires et des vues panoramiques.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const CentreAtlantiqueMaroc = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={centreat2} alt="" />
        <img src={centreat1} alt="" />
        <img src={centreat3} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Centre Atlantique Maroc</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Centre Atlantique du Maroc est une région dynamique située entre la
          côte atlantique et les montagnes de l'Atlas. Cette région abrite des
          villes historiques telles que Marrakech, Casablanca et Rabat, ainsi
          que des paysages diversifiés allant des plages aux vallées
          verdoyantes.
        </p>
        <h2>Principales attractions</h2>
        <ul>
          <li>
            Marrakech: Connue pour sa médina animée, ses souks colorés et sa
            célèbre place Jemaa el-Fna.
          </li>
          <li>
            Casablanca: Une métropole moderne avec une architecture
            impressionnante, y compris la mosquée Hassan II.
          </li>
          <li>
            Rabat: La capitale du Maroc, offrant une combinaison unique de
            patrimoine historique et de modernité.
          </li>
          <li>
            Essaouira: Une ville côtière pittoresque avec des plages magnifiques
            et une ambiance décontractée.
          </li>
        </ul>
        <h2>Culture et traditions</h2>
        <p>
          Explorez la riche culture marocaine à travers l'art, la musique, la
          cuisine et les festivals tout au long de l'année.
        </p>
        <h2>Gastronomie</h2>
        <p>
          Dégustez une cuisine délicieuse allant des tajines aux fruits de mer
          frais en passant par les plats de rue traditionnels.
        </p>
        <h2>Activités de plein air</h2>
        <p>
          Profitez de la randonnée dans les montagnes de l'Atlas, du surf sur
          les plages de la côte atlantique et de l'exploration des oasis
          verdoyantes.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const CentreDuMaroc = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={centre2} alt="" />
        <img src={centre1} alt="" />
        <img src={centre3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Centre du Maroc</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Centre du Maroc est une région qui offre une combinaison unique de
          paysages désertiques, de villes historiques et de culture berbère. Des
          destinations telles que Fès, Meknès et Beni Mellal attirent les
          voyageurs avec leur architecture ancienne et leur ambiance
          traditionnelle.
        </p>
        <h2>Désert du Sahara</h2>
        <p>
          Explorez les dunes de sable doré et découvrez la vie nomade dans le
          vaste désert du Sahara.
        </p>
        <h2>Villes impériales</h2>
        <p>
          Plongez dans l'histoire en visitant les médinas historiques et les
          palais des villes impériales de Fès et de Meknès.
        </p>
        <h2>Culture berbère</h2>
        <p>
          Découvrez les traditions millénaires des populations berbères à
          travers l'artisanat, la musique et les festivals.
        </p>
        <h2>Artisanat</h2>
        <p>
          Achetez des produits artisanaux uniques tels que les tapis berbères,
          la poterie et les bijoux dans les souks traditionnels.
        </p>
        <img src={art} alt="" style={{ width: "250px" }} />
        <Link
          to="/contact"
          className="reservez-button"
          style={{ marginTop: "5%" }}
        >
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const AtlasDuMaroc = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={atlas2} alt="" />
        <img src={atlas1} alt="" />
        <img src={atlas3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Atlas du Maroc</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Les montagnes de l'Atlas offrent une évasion spectaculaire dans la
          nature, avec des sommets enneigés, des vallées verdoyantes et des
          villages berbères traditionnels. Cette région est un paradis pour les
          amateurs de randonnée, d'escalade et d'observation de la faune et de
          la flore.
        </p>
        <h2>Randonnée</h2>
        <p>
          Parcourez les sentiers de randonnée pittoresques pour découvrir des
          paysages à couper le souffle et des villages berbères isolés.
        </p>
        <h2>Montagnes enneigées</h2>
        <p>
          Les sommets les plus élevés de l'Atlas offrent des possibilités de ski
          et de snowboard en hiver.
        </p>
        <h2>Écotourisme</h2>
        <p>
          Participez à des initiatives de tourisme responsable visant à
          préserver la beauté naturelle et la culture des communautés locales.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const SudDuMaroc = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={sud2} alt="" />
        <img src={sud1} alt="" />
        <img src={sud3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Sud du Maroc</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Sud du Maroc est une région enchanteresse caractérisée par ses
          vastes déserts, ses oasis verdoyantes et ses villages traditionnels.
          Des destinations telles que Ouarzazate, Zagora et Merzouga offrent une
          expérience authentique du désert marocain.
        </p>
        <h2>Désert du Sahara</h2>
        <p>
          Explorez les dunes de sable infinies et campez sous les étoiles dans
          le désert du Sahara.
        </p>
        <h2>Oasis verdoyantes</h2>
        <p>
          Détendez-vous dans les oasis luxuriantes et découvrez la vie
          traditionnelle des habitants dans les villages berbères.
        </p>
        <h2>Artisanat du désert</h2>
        <p>
          Admirez l'artisanat local, y compris les tapis berbères, les poteries
          et les bijoux, fabriqués par les habitants du désert.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const AfriqueDuNord = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={naf2} alt="" />
        <img src={naf1} alt="" />
        <img src={naf3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Afrique du Nord</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Afrique du Nord est une région fascinante qui regroupe plusieurs
          pays côtiers le long de la mer Méditerranée, dont le Maroc, l'Algérie,
          la Tunisie, l'Égypte et la Libye. Cette région est connue pour sa
          riche histoire, sa diversité culturelle et ses paysages variés, allant
          des déserts aux montagnes en passant par les côtes spectaculaires.
        </p>
        <h2>Destinations populaires</h2>
        <p>
          Explorez des destinations emblématiques telles que les pyramides
          égyptiennes, les médinas historiques du Maroc et les sites
          archéologiques de Tunisie.
        </p>
        <h2>Culture et traditions</h2>
        <p>
          Immergez-vous dans la culture vibrante de l'Afrique du Nord à travers
          sa musique, son artisanat, sa cuisine et ses festivals.
        </p>
        <h2>Plages et loisirs nautiques</h2>
        <p>
          Profitez des magnifiques plages de la Méditerranée pour la baignade,
          la plongée sous-marine et les sports nautiques.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const Afrique = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={afrc2} alt="" />
        <img src={afrc1} alt="" />
        <img src={afrc3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Afrique</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Afrique est un continent diversifié et captivant, offrant une
          multitude d'expériences de voyage uniques. Des safaris en Afrique de
          l'Est aux paysages époustouflants de l'Afrique australe, en passant
          par les cultures riches et les déserts mystérieux de l'Afrique du
          Nord, ce continent a tout pour plaire aux voyageurs.
        </p>
        <h2>Destinations emblématiques</h2>
        <p>
          Explorez des sites célèbres tels que le mont Kilimandjaro, les chutes
          Victoria et le delta de l'Okavango.
        </p>
        <h2>Vie sauvage</h2>
        <p>
          Partez en safari pour observer une incroyable variété d'animaux,
          notamment des lions, des éléphants, des léopards et des rhinocéros.
        </p>
        <h2>Cultures et traditions</h2>
        <p>
          Découvrez les traditions anciennes des peuples africains à travers
          leur musique, leur danse, leur artisanat et leur cuisine.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const MoyenOrient = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={mo2} alt="" />
        <img src={mo1} alt="" />
        <img src={mo3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Moyen-Orient</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Moyen-Orient est une région fascinante qui regroupe plusieurs pays
          aux cultures riches et aux paysages variés. Des destinations
          emblématiques comme l'Égypte, la Jordanie et les Émirats arabes unis
          offrent une combinaison unique de sites historiques, de déserts
          majestueux et de villes modernes.
        </p>
        <h2>Sites historiques</h2>
        <p>
          Explorez les pyramides égyptiennes, les ruines de Pétra en Jordanie et
          les cités anciennes de la Mésopotamie.
        </p>
        <h2>Déserts et oasis</h2>
        <p>
          Découvrez la beauté du désert du Sahara, du désert du Wadi Rum et des
          oasis verdoyantes disséminées à travers la région.
        </p>
        <h2>Culture et traditions</h2>
        <p>
          Imprégnez-vous de la riche culture du Moyen-Orient à travers sa
          musique, sa cuisine, son artisanat et ses festivals colorés.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const Europe = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={eu2} alt="" />
        <img src={eu1} alt="" />
        <img src={eu3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Europe</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Europe est un continent diversifié qui regorge de merveilles
          historiques, de cultures riches et de paysages variés. Des villes
          emblématiques comme Paris, Rome et Londres aux paysages naturels
          époustouflants des Alpes et des fjords norvégiens, l'Europe offre une
          infinité d'expériences de voyage.
        </p>
        <h2>Patrimoine mondial de l'UNESCO</h2>
        <p>
          Explorez les sites historiques et culturels inscrits au patrimoine
          mondial de l'UNESCO, tels que le Colisée, la tour Eiffel et le château
          de Neuschwanstein.
        </p>
        <h2>Cuisine européenne</h2>
        <p>
          Dégustez une cuisine délicieuse et variée, allant des pâtes italiennes
          aux tapas espagnoles en passant par les pâtisseries françaises.
        </p>
        <h2>Nature et plein air</h2>
        <p>
          Profitez des magnifiques paysages naturels pour la randonnée, le ski,
          la voile et d'autres activités de plein air.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const SouthAmerica = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={sameric2} alt="" />
        <img src={sameric1} alt="" />
        <img src={sameric3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Amérique du Sud</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Amérique du Sud est un continent aux paysages époustouflants, à la
          culture vibrante et à la biodiversité exceptionnelle. Des majestueuses
          chutes d'Iguazú en Argentine aux ruines incas du Machu Picchu au
          Pérou, l'Amérique du Sud offre une variété d'expériences de voyage
          inoubliables.
        </p>
        <h2>Nature et biodiversité</h2>
        <p>
          Explorez la forêt amazonienne, les glaciers de Patagonie et les îles
          Galápagos pour découvrir une biodiversité unique au monde.
        </p>
        <h2>Cultures indigènes</h2>
        <p>
          Découvrez les traditions anciennes des peuples indigènes d'Amérique du
          Sud à travers leur artisanat, leur musique et leurs célébrations.
        </p>
        <h2>Aventure et plein air</h2>
        <p>
          Participez à des activités d'aventure telles que le trekking, le
          rafting et l'escalade dans certains des paysages les plus
          spectaculaires de la planète.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const Asie = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={asia2} alt="" />
        <img src={asia1} alt="" />
        <img src={asia3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Asie</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Asie est le plus grand continent du monde, offrant une diversité
          culturelle, une histoire riche et des paysages à couper le souffle.
          Des temples anciens d'Angkor au Cambodge aux gratte-ciel modernes de
          Tokyo, l'Asie offre une combinaison de tradition et de modernité
          unique.
        </p>
        <h2>Patrimoine culturel</h2>
        <p>
          Explorez les sites historiques et religieux emblématiques, tels que le
          Taj Mahal en Inde, la Grande Muraille de Chine et les temples de
          Kyoto.
        </p>
        <h2>Cuisine asiatique</h2>
        <p>
          Découvrez une cuisine variée et délicieuse, allant des currys épicés
          de l'Inde aux sushis raffinés du Japon en passant par les nouilles
          chinoises.
        </p>
        <h2>Nature et aventures</h2>
        <p>
          Partez à la découverte des montagnes de l'Himalaya, des plages
          paradisiaques de la Thaïlande et des jungles luxuriantes de Bornéo
          pour des aventures inoubliables.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const Hadj = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={hadjj2} alt="" />
        <img src={hadjj1} alt="" />
        <img src={hadjj3} alt="" />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Hadj</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Le Hajj est le pèlerinage annuel effectué par des millions de
          musulmans vers La Mecque, en Arabie saoudite, dans le cadre des cinq
          piliers de l'islam. C'est l'un des plus grands rassemblements
          religieux au monde, où les croyants accomplissent une série de rituels
          sacrés.
        </p>
        <h2>Rituals sacrés</h2>
        <p>
          Les pèlerins effectuent une série de rituels, y compris la
          circumambulation autour de la Kaaba, la course entre les collines de
          Safa et Marwa, et le séjour à Arafat.
        </p>
        <h2>Signification spirituelle</h2>
        <p>
          Le Hajj est considéré comme un acte de purification et de renouveau
          spirituel, offrant aux croyants une occasion de se rapprocher d'Allah
          et de renforcer leur foi.
        </p>
        <h2>Communauté mondiale</h2>
        <p>
          Le Hajj réunit des musulmans du monde entier, créant un sentiment
          d'unité et de fraternité entre les différentes communautés et cultures
          musulmanes.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};

export const Omrah = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav className="header">
        <div className="logo-container">
          <div className="flipper">
            <div className="front">
              <img src={logoFront} alt="Front Logo" className="logo" />
            </div>
            <div className="back">
              <img src={logoBack} alt="Back Logo" className="logo back-logo" />
            </div>
          </div>
        </div>{" "}
        <div className={`aa ${isMenuOpen ? "active" : ""}`}>
          <a href="/" onClick={closeMenu}>
            Accueil
          </a>
          <div className="dropdown">
            <a href="/#destinations" onClick={closeMenu}>
              Destinations
            </a>
            <div className="dropdown-content">
              <a href="/#pelerinage">VOYAGES PELERINAGE</a>
              <a href="/#nationaux">VOYAGES NATIONAUX</a>
              <a href="/#internationaux">VOYAGES INTERNATIONAUX</a>
            </div>
          </div>
          <a href="/#services" onClick={closeMenu}>
            Services
          </a>
          <a href="/#tarifs" onClick={closeMenu}>
            Tarifs
          </a>
          <a href="/#AVIS" onClick={closeMenu}>
            Avis
          </a>
          <Link to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="intdestimg">
        <img src={omra2} alt="" />
        <img src={omra1} alt="" />
        <img src={omra3} alt="" />
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#3f51b5" }}>Omra</h1>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          L'Umrah est un pèlerinage musulman qui peut être entrepris à tout
          moment de l'année, contrairement au Hajj qui a lieu à des dates
          spécifiques. Bien qu'il ne soit pas obligatoire, l'Umrah est considéré
          comme une occasion précieuse de visiter les lieux saints de l'islam à
          La Mecque et à Médine.
        </p>
        <h2>Rituals</h2>
        <p>
          Les pèlerins accomplissent une série de rituels, y compris la
          circumambulation autour de la Kaaba, la course entre les collines de
          Safa et Marwa, et la visite de la mosquée du Prophète à Médine.
        </p>
        <h2>Signification spirituelle</h2>
        <p>
          L'Umrah est une occasion de renforcer sa foi, de demander le pardon et
          de rechercher la proximité d'Allah.
        </p>
        <h2>Bénédictions</h2>
        <p>
          Les pèlerins croient que l'accomplissement de l'Umrah apporte des
          bénédictions et des récompenses spirituelles, ainsi que la
          purification des péchés.
        </p>
        <Link to="/contact" className="reservez-button">
          Reservez
        </Link>
      </div>
    </div>
  );
};
