import React from "react";
import "./App.css";
import img from "./assets/1.jpg";
import v1 from "./assets/v1.jpg";
import o1 from "./assets/omrahp1.jpg";
import h from "./assets/h.jpg";
import istanbul from "./assets/istanbul.jpg";
import fl from "./assets/flight.jpg";
import cr from "./assets/cruise.jpg";

const destinations = [
  {
    id: 1,
    name: "Omrah",
    image: img,
    description:
      "Répondez à l'appel sacré  de l'Omrah, et laissez votre cœur se purifier dans les lieux les plus bénis.",
    link: "/booking-omrah",
  },
  {
    id: 6,
    name: "Omrah 1446",
    image: o1,
    description:
      "Répondez à l'appel sacré  de l'Omrah, et laissez votre cœur se purifier dans les lieux les plus bénis.",
    link: "/booking-omrah-1446",
  },
  {
    id: 7,
    name: "HADJ",
    image: h,
    description:
      "Répondez à l'appel sacré du Hajj et laissez votre cœur se remplir de dévotion dans les lieux les plus bénis.",
    link: "/BOOKING-HADJ",
  },
  {
    id: 8,
    name: "ISTANBUL",
    image: istanbul,
    description:
      "Découvrez Istanbul avec des offres spéciales et plongez dans son riche patrimoine culturel !",
    link: "/ISTANBUL",
  },
  {
    id: 9,
    name: "RESERVER VOS VOLS",
    image: fl,
    description:
      "Réservez vos vols vers n'importe quelle destination avec des offres irrésistibles !",
    link: "/flights",
  },
  {
    id: 10,
    name: "RESERVER VOS CROISIERES",
    image: cr,
    description:
      "Réservez vos croisières vers toutes vos destinations préférées avec des offres immanquables !",
    link: "/cruises",
  },
  {
    id: 2,
    name: "VISA",
    image: v1,
    description:
      "Obtenez votre visa facilement et rapidement chez nous, pour un voyage sans soucis !",
    link: "/visa1",
  }

  // Add more destinations as needed
];

const Offres = () => {
  return (
    <>
      <main>
        <div
          id="OFFRES"
          style={{
            position: "relative",
            zIndex: 20,
            maxWidth: "1200px",
            margin: "0 auto",
            marginTop: "10%",
            padding: "0 1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "#333",
            height: "100%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h1
            style={{
              fontSize: "2.25rem", // 4xl
              fontWeight: "bold",
              letterSpacing: "-0.015em", // tracking-tight
              margin: "1rem 0",
              paddingBottom: "0.5rem",
              fontFamily: "Arial, sans-serif",
            }}
          >
            OFFRES DE VOYAGES EXCLUSIVES
          </h1>
        </div>

        <div className="cardContainer">
          {destinations.map((destination) => (
            <div key={destination.id} className="card">
              <img
                src={destination.image}
                alt={destination.name}
                style={{ maxHeight: "300px" }}
              />
              <h2>{destination.name}</h2>
              <p>{destination.description}</p>
              <a href={destination.link}>RESERVER</a>
            </div>
          ))}
        </div>
      </main>
    </>
  );
};

export default Offres;
