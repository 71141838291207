   import logo from './assets/logo MOUMA travel_page-0001.jpg';
   import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
   import email from './assets/email.png';
   import fixe from "./assets/telephone-fixe.png";
   import facebook from "./assets/facebook.png";
   import instagram from "./assets/instagram.png";
   import tiktok from "./assets/tiktok.svg";
   import part from "./assets/partenaires.png";
   import { useState } from 'react';
   
   function Footer() {
       const mapStyles = {
           height: "300px",
           width: "100%",
           borderRadius: "5%",
           boxShadow: '2px 2px 6px rgba(0,0,0,.4)',
       };
   
       const defaultCenter = {
        lat:  33.974947,
        lng:-6.889841,
        
       };
// , , 
       const [mapLoaded, setMapLoaded] = useState(false);
   
       let redMarkerIcon = null; 
   
       if (mapLoaded) {
           redMarkerIcon = {
               
               scaledSize: new window.google.maps.Size(40, 40),
           };
       }
   
       return (
           <>
               <footer>
                   <div>
                       <img src={logo} alt="Logo" className='footerlogo' />
                       <div className='map-container'>
                           <LoadScript
                               googleMapsApiKey="AIzaSyAszn69UV5YAS2akwksaj6aCZejZdh6Twg"
                               onLoad={() => setMapLoaded(true)}
                           >
                               {mapLoaded && (
                                   <GoogleMap
                                       mapContainerStyle={mapStyles}
                                       zoom={19}
                                       center={defaultCenter}
                                   >
                                       <Marker position={defaultCenter} icon={redMarkerIcon} />
                                   </GoogleMap>
                               )}
                           </LoadScript>
                       </div>
                       <div className='smcont'>
                           <a href='https://www.facebook.com/p/SN-Travel-61551511155343/?_rdc=4&_rdr'> <img src={facebook} alt='facebook' /> </a>
                           <a href='https://www.instagram.com/sntravelmaroc/'> <img src={instagram} alt='instagram' /> </a>
                           <a href='https://www.tiktok.com/@sntravelrabat'> <img src={tiktok} alt='tiktok' /> </a>
                       </div>
                       <div className='cntct'>
                           <img src={email} alt='email' /> <p>EMAIL: contact@sntravel.com</p>
                           <img src={fixe} alt='fixe' /> <p>FIXE: 080-8627508</p>
                       </div>
                   </div>
   
                   <hr style={{ height: '2px', borderWidth: '0', color: 'gray', backgroundColor: 'gray', marginTop: '15%' }} />
                   <div><h2 style={{ textAlign: 'center' }}>NOS PARTENAIRES</h2>
                   <img src={part} alt='partenaires' style={{width:'95%',height:'40%',backgroundColor:'white' ,paddingRight:'13%'}}/>
                   
                   </div>

               </footer>
           </>
       )
   }
   
   export default Footer;
   